


























































































































































































































import api from "@/api/index"; //ABP API接口
import { Component, Vue } from "vue-property-decorator";
import {
  VolunteerDto,
  VolunteerDtoPagedResultDto,
  VolunteerPagedResultRequestDto,
  VolunteerStatus,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import EditServiceHistory from "@/views/volunteer/components/edit-serviceHistory.vue";
import ImportExcel from "@/components/ImportExcel/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "ActivityList",
  components: {
    EditServiceHistory,
    PagedTableView,
    ImportExcel,
    ExportButton,
  },
})
export default class VolunteerList extends Vue {
  queryForm: VolunteerPagedResultRequestDto = {
    volunteerName: "",
    volunteerPhone: "",
    volunteerCode: "",
    status: undefined,
    includeUserExtend: true,
  };

  id = 0;

  statusList: any[] = [];
  exportFieldList = [
    "志愿者姓名",
    "性别",
    "生辰（出生年月日）",
    "志愿者编号",
    "手机号",
    "证件号码",
    "服务时长",
    "志愿者等级",
    "加入时间",
    "所在城市",
    "国籍",
    "新新人类，为国为民，您经历的公益项目有哪些？",
    "敢问新新人类来自哪个公益组织？",
    "所属团体",
    "新新人类经验如此丰富，有何过人之处？（擅长领域）",
    "此行路途遥远，贵体可有旧疾？（如家族史、高血压等，请如实告知）",
    "新新人类因何动机来此报名?",
    "是时候表演真正的功夫了，您可以胜任哪类任务？",
    "生命大健康揭秘，您的目标是哪个段位？",
    "成就大众，您愿意联系自己的公益组织与我们共谋生命大健康吗？",
    "E-mail",
    "QQ",
    "反面",
    "正面",
    "联系地址",
    "教育经历",
    "教育程度",
    "政治面貌",
    "工作单位",
    "单位所属行业",
    "工作经历",
    "擅长的语言",
    "紧急联系人",
    "推荐志愿者编码",
    "照片上传",
  ];

  get VolunteerStatus(): any {
    return VolunteerStatus;
  }

  created(): void {
    api.enumService
      .getValues({ typeName: "VolunteerStatus" })
      .then((res: any) => {
        this.statusList = res;
      });
  }

  // 获取表数据
  fetchData(params: any): Promise<VolunteerDtoPagedResultDto> {
    console.log("test:" + params);
    return api.volunteer.getAll(params);
  }

  handleDetail(index: number, row: VolunteerDto): void {
    this.$router.push({
      name: "VolunteerDetail",
      params: { id: `${row.id!}` },
      query: {},
    });
  }

  handleOnSaved(): void {
    this.fetchData(this.queryForm);
  }

  // 新建
  handleCreate(): void {
    this.$router.push({
      name: "volunteerCreate",
    });
  }

  handleEdit(index: number, row: VolunteerDto) {
    this.$router.push({
      name: "volunteerEdit",
      params: { id: row.id + "" },
    });
  }
  handleDelete(index: number, row: VolunteerDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.volunteer
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handleServiceHistory(index: number, row: VolunteerDto) {
    api.volunteerServiceHistory
      .getForEdit({
        id: 0,
      })
      .then(() => {
        let form = this.$refs.serviceHistoryForm as any;
        form.volunteerId = row.id;
        form.show = true;
      });
  }

  handleBan(index: number, row: VolunteerDto) {
    this.$confirm("你确定拉黑吗?", "提示").then(async () => {
      api.volunteer
        .ban({
          body: {
            id: row.id,
          },
        })
        .then(() => {
          this.fetchData(this.queryForm);
        });
    });
  }

  handleCancelBan(index: number, row: VolunteerDto) {
    api.volunteer
      .cancelBan({
        body: {
          id: row.id,
        },
      })
      .then(() => {
        this.fetchData(this.queryForm);
      });
  }

  handleAudit(index: number, row: VolunteerDto) {
    api.volunteer.postToAudit({ body: { id: row.id } }).then(() => {
      this.fetchData(this.queryForm);
    });
  }

  handleReAudit(index: number, row: VolunteerDto) {
    api.volunteer.postTorReAudit({ body: { id: row.id } }).then(() => {
      this.fetchData(this.queryForm);
    });
  }
}
